<template>
    <BitcoinHoje />
</template>

<script>

import BitcoinHoje from '../components/bitcoinHoje.vue'


export default {
    name: 'BNB',
    data(){
        return{
            
        }
    },

    components: {
        BitcoinHoje
    },
}
</script>